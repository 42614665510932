@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-decoration: none;
}
a {
    display: inline-block;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
/*@define-mixin headingSize $size: 1.8rem, $spacing: 7px
{
  @for $i from 6 through 1{
    h#{$i}{
      @extend %heading;
      font-size: $size;
      letter-spacing: $spacing;
    }
    $size: $size + .4rem;
    $spacing: $spacing * 1.2;
  }
}*/
/*@mixin break($args...) {
    @if length($args) == 1 {
        @media (min-width: nth($args, 1)) {
            @content;
        }
    } @else {
        @media (min-width: nth($args, 1))
            and (max-width: nth($args, 2)) {
            @content;
        }
    }

}*/
/*====================MAIN PAGE STYLES============*/
/* Track */
html {
    /*---sets font size using rem to exact pixel. for example 1.8rem = 18 px.*/
    font-size: 62.5%;
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
body {
    margin: 0;
    height: 100vh;
    width: 100vw;
    padding: 0;
    color: black;
    font-family: 'Roboto',sans-serif;
    font-size: 1.8rem;
    line-height: 160%;
    font-weight: 300;
    background: -webkit-linear-gradient( bottom, transparent, transparent), url(../images/backgrounds/nature-field-forest-agriculture.jpg);
    background: linear-gradient( to top, transparent, transparent), url(../images/backgrounds/nature-field-forest-agriculture.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    height: 100vh;
    max-height: 100vh;
}
@media screen and (min-width: 600px) {
    body {
        background: -webkit-linear-gradient( bottom, transparent, transparent), url(../images/backgrounds/nature-field-forest-agriculture.jpg);
        background: linear-gradient( to top, transparent, transparent), url(../images/backgrounds/nature-field-forest-agriculture.jpg);
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center center;
        background-size: cover;
        height: 100vh;
        max-height: 100vh;
    }
}
@media screen and (min-width: 1200px) {
    body {
        background: -webkit-linear-gradient( bottom, transparent, transparent), url(../images/backgrounds/nature-field-forest-agriculture.jpg);
        background: linear-gradient( to top, transparent, transparent), url(../images/backgrounds/nature-field-forest-agriculture.jpg);
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center center;
        background-size: cover;
        height: 100vh;
        max-height: 100vh;
    }
}
h1, h2, h3, h4 {
    font-family: 'Roboto',sans-serif;
    line-height: 1.5;
    word-wrap: break-word;
    text-align: center;
    color: white;
    padding: 1rem 0;
    font-weight: 500;
    margin: 0;
}
/*--adds .4 rem to each heading fz and character spacing 1-6---*/
h1 {
    font-size: 2.5rem;
}
@media screen and (min-width: 380px) {
    h1 {
        font-size: 3.0rem;
    }
}
@media screen and (min-width: 600px) {
    h1 {
        font-size: 3.2rem;
    }
}
@media screen and (min-width: 760px) {
    h1 {
        font-size: 4.0rem;
    }
}
@media screen and (min-width: 1200px) {
    h1 {
        font-size: 4.4rem;
    }
}
/*end of h1*/
h2 {
    font-size: 2.0rem;
}
@media screen and (min-width: 380px) {
    h2 {
        font-size: 2.2rem;
    }
}
@media screen and (min-width: 600px) {
    h2 {
        font-size: 2.6rem;
    }
}
/*end h2*/
h3 {
    letter-spacing: normal;
    font-size: 1.8rem;
}
@media screen and (min-width: 380px) {
    h3 {
        font-size: 2.0rem;
    }
}
@media screen and (min-width: 600px) {
    h3 {
        font-size: 2.4rem;
    }
}
/*end of h3*/
a {
    color: rgb(190, 185, 21);
}
a:hover {
    color: rgb(255, 225, 72);
    border-bottom: 3px solid rgb(153, 135, 43);
}
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    line-height: 1.1;
    background: rgb(113, 105, 4);
    font-size: 1.4rem;
}
.footer .contact {
    margin: 1rem 0;
}
.footer a {
    display: block;
    text-align: center;
}
@media screen and (min-width: 760px) {
    .footer {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        height: 56px;
    }
    .footer a {
        display: inline-block;
    }
}
/*end of footer*/
#front-page {
    /*end of #owners*/
}
#front-page #title {
    padding: 8rem 3rem rem;
}
#front-page #owners {
    margin: 0 auto;
    background: rgba(113, 105, 4, 0.8);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /*end of address*/
}
#front-page #owners address {
    color: white;
    text-align: center;
    padding: 2rem 1rem 2rem;
}
#front-page #owners address a {
    display: block;
}
#front-page #owners address:last-of-type {
    padding-top: 0;
}
@media screen and (min-width: 760px) {
    #front-page #owners {
        width: 60%;
        font-size: 2.4rem;
    }
}
/*end of #front-page*/
